export const roleTypes = {
  ADMIN: "Admin",
  EMPLOYEE: "Employee",
  FRONT_DESK: "Front Desk",
  SCHEDULING_MANAGER: "Scheduling Manager",
  INVOICING_MANAGER: "Invoicing Manager",
  LEAD: "Lead",
  CUSTOMER: "Customer",
  QC_MANAGER: "QC Manager",
};
