import React, { Suspense, useState, useEffect, useCallback } from "react";
import { ApplicationPaths } from "../../components/api-authorization/ApiAuthorizationConstants";
import Loading from "../../components/common/Loading";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";
import AuthorizeRoute from "../../components/api-authorization/AuthorizeRoute";
import authService from "../../components/api-authorization/AuthorizeService";

// routes config
import routes from "../routes";

const ManageCustomerList = React.lazy(() =>
  import(
    "../../components/customer-management/customer-list/ManageCustomerList"
  )
);

const CustomerDetail = React.lazy(() =>
  import("../../components/customer-management/customer-details/CustomerDetail")
);

const LeadBonusReport = React.lazy(() =>
  import("../../components/reporting/lead-bonus/LeadBonusReport")
);

const ManageEmployeeIssues = React.lazy(() =>
  import(
    "../../components/employee-management/employee-issues/ManageEmployeeIssues"
  )
);

const ViewSchedule = React.lazy(() =>
  import("../../components/schedule/view-schedule/ViewSchedule")
);

const ManageComplexesList = React.lazy(() =>
  import("../../components/complexes/complexes-list/ManageComplexesList")
);

const ManageInvoiceStatusList = React.lazy(() =>
  import(
    "../../components/invoicing/invoice-status/invoice-status-list/ManageInvoiceStatusList"
  )
);

const ManageReadyForInvoiceList = React.lazy(() =>
  import(
    "../../components/invoicing/ready-for-invoice/ready-for-invoice-list/ManageReadyForInvoiceList"
  )
);

const BigJob = React.lazy(() =>
  import("../../components/reporting/big-job/BigJob")
);

const InvoicedChargesReport = React.lazy(() =>
  import("../../components/reporting/invoiced-charges/InvoicedChargesReport")
);

const LeadComplexTaskCountReport = React.lazy(() =>
  import(
    "../../components/reporting/lead-complex-task-count/LeadComplexTaskCountReport"
  )
);

const ManageEmployeeList = React.lazy(() =>
  import(
    "../../components/employee-management/employee-list/ManageEmployeeList"
  )
);

const ManageVendorsList = React.lazy(() =>
  import("../../components/vendors/vendors-list/ManageVendorsList")
);

const ManageSuppliesList = React.lazy(() =>
  import("../../components/supplies/supplies-list/ManageSuppliesList")
);

const ManageSupplyPurchaseHeaders = React.lazy(() =>
  import(
    "../../components/supplies/supply-purchases/ManageSupplyPurchaseHeaders"
  )
);

const ManageSupplyPurchases = React.lazy(() =>
  import("../../components/supplies/supply-purchases/ManageSupplyPurchases")
);

const ComplexDetail = React.lazy(() =>
  import("../../components/complexes/complex-detail/ComplexDetail")
);

const EmployeeDetail = React.lazy(() =>
  import("../../components/employee-management/employee-details/EmployeeDetail")
);

const Settings = React.lazy(() => import("../../components/settings/Settings"));

const ManageQualityCheck = React.lazy(() =>
  import("../../components/quality-control/ManageQualityCheck")
);

const PaintStoreDetail = React.lazy(() =>
  import("../../components/paint-store/PaintStoreDetail")
);

const MgtCompanyDetail = React.lazy(() =>
  import("../../components/management-company/MgtCompanyDetail")
);

const ManageSchedule = React.lazy(() =>
  import("../../components/schedule/manage-schedule/ManageSchedule")
);

const ManageDailySheetsList = React.lazy(() =>
  import("../../components/daily-sheets/daily-sheet/ManageDailySheetsList")
);

const ManageDailySheetReview = React.lazy(() =>
  import(
    "../../components/daily-sheets/daily-sheet-review/ManageDailySheetReview"
  )
);

const JobHeaderDetail = React.lazy(() =>
  import("../../components/schedule/job-header/JobHeaderDetail")
);

const ManageContracts = React.lazy(() =>
  import("../../components/invoicing/hallway-contracts/ManageContracts")
);

const ApartmentSizeMappings = React.lazy(() =>
  import("../../components/apartment-size-mapping/ApartmentSizeMappingList")
);

const HoldingTank = React.lazy(() =>
  import("../../components/holding-tank/HoldingTank")
);

const BidCreator = React.lazy(() =>
    import("../../components/bid-creator/BidCreator")
);

const loading = <Loading />;

const TheContent = () => {
  const [isLoggedIn, setLoginStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pathArray, setPathArray] = useState([]);
  const [isCustomer, setIsCustomer] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await authService
      .isAuthenticated()
      .then(async function (value) {
        value ? setLoginStatus(true) : setLoginStatus(false);
        authService.isCustomer((res) => {
          setIsCustomer(res);
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(setIsLoading(false));
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    fetchData();
    let pathArray = [];
    for (const path in ApplicationPaths) {
      pathArray.push(ApplicationPaths[path]);
    }

    isSubscribed && setPathArray(pathArray);

    return () => {
      isSubscribed = false;
    };
  }, [fetchData]);

  return (
    <main className="c-main">
      {isLoading ? (
        <></>
      ) : (
        <CContainer fluid>
          <Suspense fallback={loading}>
            <Switch>
              <AuthorizeRoute
                exact
                path="/holding-tank"
                name="Holding Tank"
                component={HoldingTank}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/bid-creator"
                name="Bid Creator"
                component={BidCreator}
                requiresAdmin={true}
              />
              <AuthorizeRoute
                exact
                path="/apartment-size-mappings"
                name="Apartment Size Mappings"
                component={ApartmentSizeMappings}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/complexes/complex-detail/:id"
                name="Complex Detail"
                component={ComplexDetail}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/daily-sheet-review"
                name="Daily Sheet Review"
                component={ManageDailySheetReview}
                requiresAdmin={true}
                requiresInvoicingManager={true}
                requiresSchedulingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/daily-sheet"
                name="Daily Sheet"
                component={ManageDailySheetsList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresLead={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/complexes/complex-detail/:id"
                name="Complex Detail"
                component={ComplexDetail}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/complexes/create-complex/"
                name="Create New Complex"
                component={ComplexDetail}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/complexes"
                name="Complexes"
                component={ManageComplexesList}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/invoicing/invoice-status"
                name="Invoice Status"
                component={ManageInvoiceStatusList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresCustomer={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/invoicing/contracts"
                name="Contracts"
                component={ManageContracts}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/invoicing/manage-invoice/:invoiceNumber"
                name="Manage Invoice"
                component={ManageReadyForInvoiceList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/invoicing/ready-for-invoice"
                name="Ready For Invoice"
                component={ManageReadyForInvoiceList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/lead-bonus"
                name="Lead Bonus Report"
                component={LeadBonusReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresLead={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/big-job"
                name="Big Job"
                component={BigJob}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/invoiced-charges"
                name="Charges By Complex Report"
                component={InvoicedChargesReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/management-company"
                name="Management Company Report"
                component={InvoicedChargesReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/invoiced-lead-charges"
                name="Charges By Lead Report"
                component={InvoicedChargesReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path={"/reporting/lead-complex-task-count"}
                name={"Task Count Lead Report"}
                component={LeadComplexTaskCountReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/reporting/cleaner-pay"
                name="Cleaner Pay"
                component={InvoicedChargesReport}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresLead={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/quality-control-check-status"
                name="Quality Control Check"
                component={ManageQualityCheck}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresQCManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/my-profile/view-issues"
                name="View Issues"
                component={ManageEmployeeIssues}
                requiresLead={true}
                requiresEmployee={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/my-profile"
                name="My Profile"
                component={EmployeeDetail}
                requiresLead={true}
                requiresEmployee={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/employee-management/employee-detail/:id/manage-issues"
                name="Manage Issues"
                component={ManageEmployeeIssues}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/employee-management/employee-detail/:id"
                name="Employee Detail"
                component={EmployeeDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/employee-management/create-employee"
                name="Create New Employee"
                component={EmployeeDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/employee-management"
                name="Employee Management"
                component={ManageEmployeeList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
                requiresLead={true}
              />
              <AuthorizeRoute
                exact
                path="/my-account"
                name="My Account"
                component={CustomerDetail}
                requiresCustomer={true}
              />
              <AuthorizeRoute
                exact
                path="/customer-management/create-customer"
                name="Create Customer Account"
                component={CustomerDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
              />
              <AuthorizeRoute
                exact
                path="/customer-management/customer-detail/:id"
                name="Customer Account"
                component={CustomerDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
              />
              <AuthorizeRoute
                exact
                path="/customer-management"
                name="Customer Management"
                component={ManageCustomerList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
              />
              <AuthorizeRoute
                exact
                path="/vendors"
                name="Vendors"
                component={ManageVendorsList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/supplies"
                name="Supply Prices"
                component={ManageSuppliesList}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/supply-purchases"
                name="Supply Purchases"
                component={ManageSupplyPurchaseHeaders}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/supply-purchases:id"
                name="Manage Purchases"
                component={ManageSupplyPurchases}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/settings"
                name="Settings"
                component={Settings}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/paint-store-detail/:id"
                name="Editing Paint Store"
                component={PaintStoreDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/paint-store-detail"
                name="Creating Paint Store"
                component={PaintStoreDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
              />
              <AuthorizeRoute
                exact
                path="/management-company-detail/:id"
                name="Editing Management Company"
                component={MgtCompanyDetail}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/management-company-detail"
                name="Creating Management Company"
                component={MgtCompanyDetail}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/manage-customer-jobs"
                name="Ordered Jobs"
                component={ManageSchedule}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresCustomer={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/manage-team-schedules"
                name="Scheduled Teams"
                component={ManageSchedule}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresFrontDesk={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/create-schedule"
                name="New Schedule"
                component={JobHeaderDetail}
                requiresAdmin={true}
                requiresSchedulingManager={true}
                requiresInvoicingManager={true}
                requiresFrontDesk={true}
                requiresQCManager={true}
              />
              <AuthorizeRoute
                exact
                path="/view-schedule"
                name="View Schedule"
                component={ViewSchedule}
                requiresAdmin={true}
                requiresFrontDesk={true}
                requiresSchedulingManager={true}
                requiresLead={true}
                requiresInvoicingManager={true}
                requiresQCManager={true}
              />
              {routes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <CFade>
                          <route.component {...props} />
                        </CFade>
                      )}
                    />
                  )
                );
              })}
              {pathArray.map((route, index) => {
                return (
                  <Route key={index} path={route} render={() => <Loading />} />
                );
              })}
              {isLoggedIn ? (
                <></>
              ) : (
                <Redirect from="/" to="/authentication/login" />
              )}
            </Switch>
          </Suspense>
        </CContainer>
      )}
    </main>
  );
};

export default React.memo(TheContent);
