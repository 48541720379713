import React from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      isAdmin: false,
      isLead: false,
      isFrontDesk: false,
      isSchedulingManager: false,
      isCustomer: false,
      isEmployee: false,
      isQCManager: false,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, ...rest } = this.props;
      if (authenticated) {
        if (this.hasValidAccess()) {
          return (
            <Route
              {...rest}
              render={(props) => {
                return <Component {...props} />;
              }}
            />
          );
        } else {
          return <Redirect to="/" />;
        }
      } else {
        return <Redirect to={redirectUrl} />;
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ authenticated });

    const isAdmin = await authService.isAdmin();
    const isFrontDesk = await authService.isFrontDesk();
    const isSchedulingManager = await authService.isSchedulingManager();
    const isInvoicingManager = await authService.isInvoicingManager();
    const isCustomer = await authService.isCustomer();
    const isLead = await authService.isLead();
    const isEmployee = await authService.isEmployee();
    const isQCManager = await authService.isQCManager();

    this.setState({
      ready: true,
      isAdmin,
      isFrontDesk,
      isSchedulingManager,
      isInvoicingManager,
      isCustomer,
      isLead,
      isEmployee,
      isQCManager,
    });
  }

  hasValidAccess() {
    if (this.props.requiresAdmin && this.state.isAdmin) {
      return true;
    }

    if (this.props.requiresLead && this.state.isLead) {
      return true;
    }

    if (this.props.requiresFrontDesk && this.state.isFrontDesk) {
      return true;
    }

    if (this.props.requiresCustomer && this.state.isCustomer) {
      return true;
    }

    if (
      this.props.requiresSchedulingManager &&
      this.state.isSchedulingManager
    ) {
      return true;
    }

    if (this.props.requiresInvoicingManager && this.state.isInvoicingManager) {
      return true;
    }

    if (this.props.requiresEmployee && this.state.isEmployee) {
      return true;
    }

    if (this.props.requiresQCManager && this.state.isQCManager) {
      return true;
    }

    return false;
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
