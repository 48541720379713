import { handleResponse, handleError, getBaseUrl, buildUri } from "./apiUtils";
const baseUrl = getBaseUrl();

export async function getCustomerDetailCount({
  searchKeys,
  queryString,
  includeInactive = false,
  complexId,
}) {
  let fetchCustomerDetailCountUri = `${baseUrl}/customerdetail/getcustomerdetailcount/`;

  if (searchKeys || queryString) {
    fetchCustomerDetailCountUri = buildUri(
      fetchCustomerDetailCountUri,
      searchKeys,
      queryString
    );

    fetchCustomerDetailCountUri = fetchCustomerDetailCountUri.concat(
      `includeInactive=${includeInactive}`
    );
  } else {
    fetchCustomerDetailCountUri = fetchCustomerDetailCountUri.concat(
      `?includeInactive=${includeInactive}`
    );
  }

  if (complexId) {
    fetchCustomerDetailCountUri = fetchCustomerDetailCountUri.concat(
      `&complexId=${complexId}`
    );
  }

  return await fetch(fetchCustomerDetailCountUri)
    .then(handleResponse)
    .catch(handleError);
}

export async function getCustomerDetails({
  searchKeys,
  queryString,
  orderKey,
  isAscending,
  skip,
  take,
  includeInactive = false,
  complexId,
}) {
  let fetchCustomerDetailsUri = `${baseUrl}/customerDetail/getcustomerdetails/`;

  if (searchKeys || queryString || orderKey || isAscending || skip || take) {
    fetchCustomerDetailsUri = buildUri(
      fetchCustomerDetailsUri,
      searchKeys,
      queryString,
      orderKey,
      isAscending,
      skip,
      take
    );

    fetchCustomerDetailsUri = fetchCustomerDetailsUri.concat(
      `includeInactive=${includeInactive}`
    );
  } else {
    fetchCustomerDetailsUri = fetchCustomerDetailsUri.concat(
      `?includeInactive=${includeInactive}`
    );
  }

  if (complexId) {
    fetchCustomerDetailsUri = fetchCustomerDetailsUri.concat(
      `&complexId=${complexId}`
    );
  }

  return await fetch(fetchCustomerDetailsUri)
    .then(handleResponse)
    .catch(handleError);
}

export async function getCustomerDetail(customerId) {
  return await fetch(
    `${baseUrl}/customerdetail/getcustomerdetail/${customerId}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllCustomersForComplex(complexId) {
  return await fetch(
    `${baseUrl}/customerdetail/getallcustomersforcomplex/${complexId}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getCustomerDetailByUserId(userId) {
  return await fetch(
    `${baseUrl}/customerdetail/getcustomerdetailbyuserid/${userId}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllEmailCustomers() {
    const customers = await getEmailCustomers({
        isAscending: true,
        orderKey: "firstName",
    });

    return customers;
}

export async function getEmailCustomers({
    searchKeys,
    queryString,
    orderKey,
    isAscending,
    skip,
    take,
}) {
    let fetchCustomersUri = `${baseUrl}/customerDetail/getemailcustomers/`;

    if (searchKeys || queryString || orderKey || isAscending || skip || take) {
        fetchCustomersUri = buildUri(
            fetchCustomersUri,
            searchKeys,
            queryString,
            orderKey,
            isAscending,
            skip,
            take
        );
    }

    return await fetch(fetchCustomersUri).then(handleResponse).catch(handleError);
}

export async function getAllInactiveCustomers() {
    const customers = await getInactiveCustomers({
        isAscending: true,
        orderKey: "firstName",
    });

    return customers;
}

export async function getInactiveCustomers({
    searchKeys,
    queryString,
    orderKey,
    isAscending,
    skip,
    take,
}) {
    let fetchCustomersUri = `${baseUrl}/customerDetail/getinactivecustomers/`;

    if (searchKeys || queryString || orderKey || isAscending || skip || take) {
        fetchCustomersUri = buildUri(
            fetchCustomersUri,
            searchKeys,
            queryString,
            orderKey,
            isAscending,
            skip,
            take
        );
    }

    return await fetch(fetchCustomersUri).then(handleResponse).catch(handleError);
}

export async function createCustomerDetail(userId, newCustomerDetail) {
  const requestUrl = `${baseUrl}/customerdetail/createcustomerdetail/${userId}`;
  delete newCustomerDetail.id;
  newCustomerDetail.email = newCustomerDetail.email || "";
  return await fetch(requestUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(newCustomerDetail),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateCustomerDetails(
  customerToUpdate,
  updatedCustomerData
) {
  const requestUrl = `${baseUrl}/customerdetail/updatecustomerdetails/${customerToUpdate.id}`;
  return await fetch(requestUrl, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      firstName:
        updatedCustomerData.firstName || customerToUpdate.firstName || null,
      lastName:
        updatedCustomerData.lastName || customerToUpdate.lastName || null,
      userName:
        updatedCustomerData.userName || customerToUpdate.userName || null,
      birthDate:
        updatedCustomerData.birthDate || customerToUpdate.birthDate || null,
      address: updatedCustomerData.address || customerToUpdate.address || null,
      state: updatedCustomerData.state || customerToUpdate.state || null,
      city: updatedCustomerData.city || customerToUpdate.city || null,
      zip: updatedCustomerData.zip || customerToUpdate.zip || null,
      email: updatedCustomerData.email || "",
      cellNumber:
        updatedCustomerData.cellNumber || customerToUpdate.cellNumber || null,
      isActive:
        updatedCustomerData.isActive !== null &&
        updatedCustomerData !== undefined
          ? updatedCustomerData.isActive
          : customerToUpdate.isActive || false,
      complexId: customerToUpdate.complexId,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateCustomerLastLogin(
    userId
) {
    const requestUrl = `${baseUrl}/customerdetail/updatecustomerlastlogin/${userId}`;
    return await fetch(requestUrl, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userId),
        })
        .then(handleResponse)
        .catch(handleError);
}
