const _customernav = [
  {
    _tag: "CSidebarNavItem",
    name: "My Profile",
    to: "/my-profile",
    icon: "cil-group",
  },
];

export default _customernav;
