import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import LogoutButton from "../../components/api-authorization/LogoutButton";
import {
  defaultDivision,
  getDivisionSecondaryButtonClass,
} from "../../utils/formatUtils";
const TheHeaderDropdown = () => {
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className={getDivisionSecondaryButtonClass(defaultDivision)}>
          Log Out
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem divider />
        <LogoutButton />
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
