const _qcManagernav = [
    {
        _tag: "CSidebarNavDropdown",
        name: "Schedule",
        icon: "cil-clipboard",
        _children: [
            {
                _tag: "CSidebarNavItem",
                to: "/manage-customer-jobs",
                name: "Ordered Jobs",
            },
            {
                _tag: "CSidebarNavItem",
                to: "/manage-team-schedules",
                name: "Scheduled Teams",
            },
        ],
    },
    {
        _tag: "CSidebarNavDropdown",
        name: "Daily Sheets",
        icon: "cil-list",
        _children: [
            {
                _tag: "CSidebarNavItem",
                to: "/daily-sheet",
                name: "Daily Sheet",
            },
        ],
    },
    {
        _tag: "CSidebarNavDropdown",
        name: "Complexes",
        icon: "cil-building",
        _children: [
            {
                _tag: "CSidebarNavItem",
                to: "/complexes",
                name: "Complex Management",
            },
            {
                _tag: "CSidebarNavItem",
                to: "/apartment-size-mappings",
                name: "Apartment Size Mappings",
            },
        ],
    },
    {
        _tag: "CSidebarNavItem",
        name: "Quality Check",
        to: "/quality-control-check-status",
        icon: "cil-check-circle",
    },
];

export default _qcManagernav;
