import React, { useState, useEffect } from "react";

const Loading = ({ style }) => {
  const [renderLoadingScreen, setRenderLoadingScreen] = useState(false);

  useEffect(() => {
    let cancelled = false;
    setTimeout(() => {
      if (!cancelled) {
        setRenderLoadingScreen(true);
      }
    }, 100);

    return () => {
      cancelled = true;
    };
  }, []);
  return (
    <>
      {renderLoadingScreen ? (
        <div className="spinner" style={style}>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Loading;
