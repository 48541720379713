import { handleResponse, handleError, getBaseUrl, buildUri } from "./apiUtils";
const baseUrl = getBaseUrl();

export async function getEmployeeCount({
  searchKeys,
  queryString,
  includeInactive = false,
}) {
  let fetchEmployeeCountUri = `${baseUrl}/employeedetail/getemployeecount/`;

  if (searchKeys || queryString) {
    fetchEmployeeCountUri = buildUri(
      fetchEmployeeCountUri,
      searchKeys,
      queryString
    );

    fetchEmployeeCountUri = fetchEmployeeCountUri.concat(
      `includeInactive=${includeInactive}`
    );
  } else {
    fetchEmployeeCountUri = fetchEmployeeCountUri.concat(
      `?includeInactive=${includeInactive}`
    );
  }

  return await fetch(fetchEmployeeCountUri)
    .then(handleResponse)
    .catch(handleError);
}

export async function getEmployees({
  searchKeys,
  queryString,
  orderKey,
  isAscending,
  skip,
  take,
  includeInactive = false,
}) {
  let fetchEmployeesUri = `${baseUrl}/employeeDetail/getemployees/`;

  if (searchKeys || queryString || orderKey || isAscending || skip || take) {
    fetchEmployeesUri = buildUri(
      fetchEmployeesUri,
      searchKeys,
      queryString,
      orderKey,
      isAscending,
      skip,
      take
    );

    fetchEmployeesUri = fetchEmployeesUri.concat(
      `includeInactive=${includeInactive}`
    );
  } else {
    fetchEmployeesUri = fetchEmployeesUri.concat(
      `?includeInactive=${includeInactive}`
    );
  }

  return await fetch(fetchEmployeesUri).then(handleResponse).catch(handleError);
}

export async function getEmailEmployees({
    searchKeys,
    queryString,
    orderKey,
    isAscending,
    skip,
    take,
}) {
    let fetchEmployeesUri = `${baseUrl}/employeeDetail/getemailemployees/`;

    if (searchKeys || queryString || orderKey || isAscending || skip || take) {
        fetchEmployeesUri = buildUri(
            fetchEmployeesUri,
            searchKeys,
            queryString,
            orderKey,
            isAscending,
            skip,
            take
        );
    }

    return await fetch(fetchEmployeesUri).then(handleResponse).catch(handleError);
}

export async function getInactiveEmployees({
    searchKeys,
    queryString,
    orderKey,
    isAscending,
    skip,
    take,
}) {
    let fetchEmployeesUri = `${baseUrl}/employeeDetail/getinactiveemployees/`;

    if (searchKeys || queryString || orderKey || isAscending || skip || take) {
        fetchEmployeesUri = buildUri(
            fetchEmployeesUri,
            searchKeys,
            queryString,
            orderKey,
            isAscending,
            skip,
            take
        );
    }

    return await fetch(fetchEmployeesUri).then(handleResponse).catch(handleError);
}

export async function getEmployee(employeeId) {
  return await fetch(`${baseUrl}/employeedetail/getemployee/${employeeId}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getQCManagerEmployees() {
  return await fetch(`${baseUrl}/employeedetail/getQCManagerEmployees`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getNextKPayId() {
  return await fetch(`${baseUrl}/employeedetail/getNextKPayId`)
    .then(handleResponse)
    .catch(handleError);
}

export async function checkIfKPayIdIsAvailable(employeeId, kPayId) {
  return await fetch(
    `${baseUrl}/employeedetail/checkIfKPayIdIsAvailable?employeeId=${employeeId}&kPayId=${kPayId}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getEmployeeByUserId(userId) {
  return await fetch(`${baseUrl}/employeedetail/getemployeebyuserid/${userId}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function createEmployeeDetail(userId, newEmployeeDetail) {
  const requestUrl = `${baseUrl}/employeedetail/createemployeedetail/${userId}`;
  const roles = newEmployeeDetail.roles.map((role) => role.value);
  newEmployeeDetail.roles = roles;
  delete newEmployeeDetail.id;
  newEmployeeDetail.email = newEmployeeDetail.email || "";
  newEmployeeDetail.division = newEmployeeDetail.division || "";
  return await fetch(requestUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(newEmployeeDetail),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateEmployeeDetails(
  employeeToUpdate,
  updatedEmployeeData
) {
  const newRoles = updatedEmployeeData.roles.map((role) => role.value);
  const oldRoles = employeeToUpdate.roles.map((role) => role.value);

  const requestUrl = `${baseUrl}/employeedetail/updateemployeedetails/${employeeToUpdate.id}`;
  return await fetch(requestUrl, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      firstName:
        updatedEmployeeData.firstName || employeeToUpdate.firstName || null,
      lastName:
        updatedEmployeeData.lastName || employeeToUpdate.lastName || null,
      shortName:
        updatedEmployeeData.shortName || employeeToUpdate.shortName || null,
      userName:
        updatedEmployeeData.userName || employeeToUpdate.userName || null,
      kPayId: updatedEmployeeData.kPayId || employeeToUpdate.kPayId || null,
      hiredDate:
        updatedEmployeeData.hiredDate || employeeToUpdate.hiredDate || null,
      referredBy:
        updatedEmployeeData.referredBy || employeeToUpdate.referredBy || null,
      address: updatedEmployeeData.address || employeeToUpdate.address || null,
      state: updatedEmployeeData.state || employeeToUpdate.state || null,
      city: updatedEmployeeData.city || employeeToUpdate.city || null,
      zip: updatedEmployeeData.zip || employeeToUpdate.zip || null,
      shirtSize:
        updatedEmployeeData.shirtSize || employeeToUpdate.shirtSize || null,
      sweatshirtSize:
        updatedEmployeeData.sweatshirtSize ||
        employeeToUpdate.sweatshirtSize ||
        null,
      email: updatedEmployeeData.email || "",
      phoneCarrier:
        updatedEmployeeData.phoneCarrier ||
        employeeToUpdate.phoneCarrier ||
        null,
      cellNumber:
        updatedEmployeeData.cellNumber || employeeToUpdate.cellNumber || null,
      division: updatedEmployeeData.division,
      roles: newRoles || oldRoles || null,
      worksMondays:
        updatedEmployeeData.worksMondays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksMondays
          : employeeToUpdate.worksMondays || false,
      worksTuesdays:
        updatedEmployeeData.worksTuesdays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksTuesdays
          : employeeToUpdate.worksTuesdays || false,
      worksWednesdays:
        updatedEmployeeData.worksWednesdays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksWednesdays
          : employeeToUpdate.worksWednesdays || false,
      worksThursdays:
        updatedEmployeeData.worksThursdays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksThursdays
          : employeeToUpdate.worksThursdays || false,
      worksFridays:
        updatedEmployeeData.worksFridays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksFridays
          : employeeToUpdate.worksFridays || false,
      worksSaturdays:
        updatedEmployeeData.worksSaturdays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksSaturdays
          : employeeToUpdate.worksSaturdays || false,
      worksSundays:
        updatedEmployeeData.worksSundays !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.worksSundays
          : employeeToUpdate.worksSundays || false,
      isActive:
        updatedEmployeeData.isActive !== null &&
        updatedEmployeeData !== undefined
          ? updatedEmployeeData.isActive
          : employeeToUpdate.isActive || false,
      leadTier:
        updatedEmployeeData.leadTier != null
          ? updatedEmployeeData.leadTier
          : employeeToUpdate.leadTier || null,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllActiveEmployees() {
  const employees = await getEmployees({
    isAscending: true,
    orderKey: "firstName",
    includeInactive: false,
  });

  return employees;
}

export async function getAllEmailEmployees() {
    const employees = await getEmailEmployees({
        isAscending: true,
        orderKey: "firstName",
    });

    return employees;
}

export async function getAllInactiveEmployees() {
    const employees = await getInactiveEmployees({
        isAscending: true,
        orderKey: "firstName",
    });

    return employees;
}

export async function getActiveLeads(division, includeInactive) {
  let requestUrl = `${baseUrl}/employeedetail/getactiveleads?`;

  if (division) {
    requestUrl = requestUrl.concat(`&division=${division}`);
  }

  if (includeInactive) {
    requestUrl = requestUrl.concat(`&includeInactive=${true}`);
  }

  return await fetch(requestUrl).then(handleResponse).catch(handleError);
}

export async function getActiveOfficeStaff(includeInactive) {
  let requestUrl = `${baseUrl}/employeedetail/getActiveOfficeStaff?`;

  if (includeInactive) {
    requestUrl = requestUrl.concat(`&includeInactive=${true}`);
  }

  return await fetch(requestUrl).then(handleResponse).catch(handleError);
}

export async function updateEmployeeTimeOff(employeeDetailId, daysOff) {
  let requestUrl = `${baseUrl}/employeedetail/updateEmployeeTimeOff/${employeeDetailId}`;

  return await fetch(requestUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(daysOff),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getEmployeeTimeOff(employeeDetailId) {
  let requestUrl = `${baseUrl}/employeedetail/getEmployeeTimeOff/${employeeDetailId}`;

  return await fetch(requestUrl).then(handleResponse).catch(handleError);
}

export async function getEmployeeIssue(employeeIssueId) {
  const requestUrl = `${baseUrl}/employeedetail/GetEmployeeIssue/${employeeIssueId}`;

  return await fetch(requestUrl).then(handleResponse).catch(handleError);
}

export async function getEmployeeIssues(employeeId, startDate, endDate) {
  let requestUrl = `${baseUrl}/employeedetail/GetEmployeeIssuesForDateRange/${employeeId}?`;

  if (startDate) {
    requestUrl = requestUrl + `startDate=${startDate}&`;
  }

  if (endDate) {
    requestUrl = requestUrl + `endDate=${endDate}&`;
  }

  return await fetch(requestUrl).then(handleResponse).catch(handleError);
}

export async function createEmployeeIssue(employeeIssueForCreation) {
  let requestUrl = `${baseUrl}/employeedetail/CreateEmployeeIssue`;
  delete employeeIssueForCreation.id;

  return await fetch(requestUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(employeeIssueForCreation),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateEmployeeIssue(employeeIssueForUpdate) {
  const requestUrl = `${baseUrl}/employeedetail/UpdateEmployeeIssue/${employeeIssueForUpdate.id}`;
  return await fetch(requestUrl, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(employeeIssueForUpdate),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteEmployeeIssue(employeeIssueId) {
  const requestUrl = `${baseUrl}/employeedetail/DeleteEmployeeIssue/${employeeIssueId}`;

  return await fetch(requestUrl, {
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTrainee(trainerId, traineeId) {
    let requestUrl = `${baseUrl}/employeedetail/getTrainee/${trainerId}/${traineeId}`;

    return await fetch(requestUrl, {
        method: "GET",
    })
        .then(handleResponse)
        .catch(handleError);
}

export async function getTrainees(trainerId) {
  let requestUrl = `${baseUrl}/employeedetail/getTrainees/${trainerId}`;

  return await fetch(requestUrl, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAvailableTrainees(trainerId) {
  let requestUrl = `${baseUrl}/employeedetail/getAvailableTrainees/${trainerId}`;

  return await fetch(requestUrl, {
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function addTrainee(trainerId, traineeId) {
  let requestUrl = `${baseUrl}/employeedetail/addTrainee/${trainerId}/${traineeId}`;

  return await fetch(requestUrl, {
    method: "POST",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateTraineeDates(id, startDate, endDate) {
    let requestUrl = `${baseUrl}/employeedetail/updateTraineeDates/${id}?`;

    if (startDate) {
        requestUrl = requestUrl + `startDate=${startDate}&`;
    }

    if (endDate) {
        requestUrl = requestUrl + `endDate=${endDate}&`;
    }

    return await fetch(requestUrl, {
        method: "POST",
    })
        .then(handleResponse)
        .catch(handleError);
}

export async function removeTrainee(trainerId, traineeId) {
  let requestUrl = `${baseUrl}/employeedetail/removeTrainee/${trainerId}/${traineeId}`;

  return await fetch(requestUrl, {
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateEmployeeLastLogin(
    userId
) {
    const requestUrl = `${baseUrl}/employeedetail/updateemployeelastlogin/${userId}`;
    return await fetch(requestUrl, {
            method: "PUT",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userId),
        })
        .then(handleResponse)
        .catch(handleError);
}
