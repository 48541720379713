import { toast } from "react-toastify";

export async function handleResponse(response, ...props) {
  if (response.status === 204) return;
  // If response is a redirected, we are being forced to authorize by server.
  // Can't parse as json or do anything with it, so force redirect to login.
  if (response.redirected) {
    window.location.pathname = "/account/login";
    return {};
  }
  if (response.status === 200 || response.status === 201) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      return response.text();
    }
  }
  if (response.status >= 400 || response.status < 500) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// TODO: call an error logging service.
export function handleError(originalError) {
  // User aborted request
  if (originalError.code === 20) {
    return;
  }

  console.error("API call failed. " + originalError);

  try {
    const parsedError = JSON.parse(originalError.message);
    throw parsedError;
  } catch (parsedError) {
    // We need to handle parsed json and strings differently, if detail is defined, we can throw the detail.
    throw parsedError.detail || originalError.message || originalError;
  }
}

export function getBaseUrl() {
  return window.location.origin;
}
export function buildUri(
  baseUri,
  searchKeys,
  queryString,
  orderKey,
  isAscending,
  skip,
  take
) {
  let uri = baseUri;

  if (searchKeys) {
    uri = uri.concat(`(${searchKeys.map((key) => key)})`);
  }

  uri = uri.concat("?");

  if (queryString) {
    uri = uri.concat(`queryString=${queryString}&`);
  }

  if (orderKey) {
    uri = uri.concat(`orderKey=${orderKey}&`);
  }

  if (isAscending !== undefined) {
    uri = uri.concat(`isAscending=${isAscending}&`);
  }

  if (skip) {
    uri = uri.concat(`skip=${skip}&`);
  }

  if (take) {
    uri = uri.concat(`take=${take}&`);
  }

  return uri;
}

export const handleImportResponse = async (response) => {
  if (response.status !== 200) {
    toast.error(
      "There was a problem importing jobs. Please verify the document for import is formatted correctly."
    );
  }
  const jsonResponse = await response.json();

  if (jsonResponse.length === 0) {
    toast.success("Import Successful");
  } else {
    let textResponse = "";
    let hasErrors = false;
    jsonResponse.forEach((rowWithError) => {
      if (rowWithError.rowIndex === 0) {
        hasErrors = true;
        textResponse = textResponse.concat(`${rowWithError.errorMessage}\n\n`);
      } else {
        textResponse = textResponse.concat(
          `Row: ${rowWithError.rowIndex} ${rowWithError.errorMessage}\n`
        );
      }
    });

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let link = document.createElement("a");
    link.download = `Errors_${
      today.getMonth() + 1
    }_${today.getDate()}_${today.getFullYear()}.txt`;
    let blob = new Blob([textResponse], { type: "text/plain" });
    link.href = window.URL.createObjectURL(blob);
    link.click();
    link.remove();
    if (hasErrors) {
      toast.error("Import Failed: Please see the attached file");
    } else {
      toast.warning(
        "Import Successful With Warnings: Please see the attached file"
      );
    }
  }
};
