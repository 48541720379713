import {
  cilList,
  cilLockLocked,
  cilPrint,
  cilClipboard,
  cilBuilding,
  cilFindInPage,
  cilCash,
  cilAccountLogout,
  cilHistory,
  cilArrowLeft,
  cilArrowRight,
  cilCaretBottom,
  cilCaretTop,
  cilSearch,
  cilChartLine,
  cilFilter,
  cilSpreadsheet,
  cilBarChart,
  cilPaintBucket,
  cilPaint,
  cilDescription,
  cilGroup,
  cilLibrary,
  cilSettings,
  cilOptions,
  cilCheckCircle,
  cilFolderOpen,
  cilNoteAdd,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    cilFolderOpen,
    cilNoteAdd,
    cilOptions,
    cilSettings,
    cilLibrary,
    cilGroup,
    cilDescription,
    cilPaint,
    cilPaintBucket,
    cilBarChart,
    cilSpreadsheet,
    cilFilter,
    cilSearch,
    cilCaretBottom,
    cilCaretTop,
    cilArrowRight,
    cilArrowLeft,
    cilChartLine,
    cilHistory,
    cilAccountLogout,
    cilFindInPage,
    cilCash,
    cilBuilding,
    cilClipboard,
    cilList,
    cilLockLocked,
    cilPrint,
    cilCheckCircle,
  }
);
