import { UserManager, WebStorageStateStore } from "oidc-client";
import { ApplicationPaths, ApplicationName } from "./ApiAuthorizationConstants";
import { roleTypes } from "../../constants/role-types";
import { updateCustomerLastLogin, getCustomerDetailByUserId } from "../../api/customerApi"

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
    const user = await this.getUser();
    this._user = user;
    return !!user;
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    await this.ensureUserManagerInitialized();
    const user = await this.userManager.getUser();
    return user && user.profile;
  }

  async blockOperationOnSelf(operation, targetUser) {
    const loggedInUser = await this.getUser();
    if (loggedInUser.id === targetUser.id) {
      throw new Error(
        "Attempting to perform illegal operation on yourself."
      ).toString();
    } else {
      try {
        return await operation();
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized();
    const user = await this.userManager.getUser();
    return user && user.access_token;
  }

  async isAdmin() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.ADMIN ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.ADMIN) > -1)
      );
    }
    return false;
  }

  async isFrontDesk() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.FRONT_DESK ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.FRONT_DESK) > -1)
      );
    }
    return false;
  }

  async isSchedulingManager() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.SCHEDULING_MANAGER ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.SCHEDULING_MANAGER) > -1)
      );
    }
    return false;
  }

  async isInvoicingManager() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.INVOICING_MANAGER ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.INVOICING_MANAGER) > -1)
      );
    }
    return false;
  }

  async isCustomer() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.CUSTOMER ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.CUSTOMER) > -1)
      );
    }
    return false;
  }

  async isLead() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.LEAD ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.LEAD) > -1)
      );
    }
    return false;
  }

  async hasLeadAccess() {
    const isLead = await this.isLead();
    const isAdmin = await this.isAdmin();

    return isLead || isAdmin;
  }

  async isEmployee() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.EMPLOYEE ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.EMPLOYEE) > -1)
      );
    }
    return false;
  }

  async isQCManager() {
    if (this._user) {
      let roles =
        this._user[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      return (
        roles === roleTypes.QC_MANAGER ||
        (Array.isArray(roles) &&
          roles.findIndex((r) => r === roleTypes.QC_MANAGER) > -1)
      );
    }
    return false;
  }

  async getUserInformation() {
    if (this._user) {
      return this._user;
    }
    await this.ensureUserManagerInitialized();
    const user = await this.userManager.getUser();
    return user && user;
  }

  // We try to authenticate the user in two different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state) {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager.signinSilent(
        this.createArguments()
      );
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log("Silent authentication error: ", silentError);

      try {
        await this.userManager.signinRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectError) {
        console.log("Redirect authentication error: ", redirectError);
        return this.error(redirectError);
      }
    }
  }

  async completeSignIn(url) {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager.signinCallback(url);
      this.updateState(user);
      return this.success(user && user.state);
    } catch (error) {
      console.log("There was an error signing in: ", error);
      return this.error("There was an error signing in.");
    }
  }

  async signOut(state) {
    await this.ensureUserManagerInitialized();
    try {
      await this.userManager.signoutRedirect(this.createArguments(state));
      return this.redirect();
    } catch (redirectSignOutError) {
      console.log("Redirect signout error: ", redirectSignOutError);
      return this.error(redirectSignOutError);
    }
  }

  async completeSignOut(url) {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager.signoutCallback(url);
      this.updateState(null);
      return this.success(response && response.data);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  updateState(user) {
      this._user = user;
      if (user) {
          updateCustomerLastLogin(user.profile.id);
      }
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++,
    });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`
      );
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state) {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message) {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    let response = await fetch(
      ApplicationPaths.ApiAuthorizationClientConfigurationUrl
    );
    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    let settings = await response.json();
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    });

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser();
      this.updateState(undefined);
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
