import React from "react";

const LeadBonusReport = React.lazy(() =>
  import("../components/reporting/lead-bonus/LeadBonusReport")
);

const ManageEmployeeIssues = React.lazy(() =>
  import(
    "../components/employee-management/employee-issues/ManageEmployeeIssues"
  )
);

const ManageDailySheetReview = React.lazy(() =>
  import("../components/daily-sheets/daily-sheet-review/ManageDailySheetReview")
);

const ManageDailySheetsList = React.lazy(() =>
  import("../components/daily-sheets/daily-sheet/ManageDailySheetsList")
);

const ViewSchedule = React.lazy(() =>
  import("../components/schedule/view-schedule/ViewSchedule")
);

const ManageComplexesList = React.lazy(() =>
  import("../components/complexes/complexes-list/ManageComplexesList")
);

const EmployeeDetail = React.lazy(() =>
  import("../components/employee-management/employee-details/EmployeeDetail")
);

const CustomerDetail = React.lazy(() =>
  import("../components/customer-management/customer-details/CustomerDetail")
);

const ManageInvoiceStatusList = React.lazy(() =>
  import(
    "../components/invoicing/invoice-status/invoice-status-list/ManageInvoiceStatusList"
  )
);

const ManageReadyForInvoiceList = React.lazy(() =>
  import(
    "../components/invoicing/ready-for-invoice/ready-for-invoice-list/ManageReadyForInvoiceList"
  )
);

const ComplexDetail = React.lazy(() =>
  import("../components/complexes/complex-detail/ComplexDetail")
);

const ManageEmployeeList = React.lazy(() =>
  import("../components/employee-management/employee-list/ManageEmployeeList")
);

const ManageCustomerList = React.lazy(() =>
  import("../components/customer-management/customer-list/ManageCustomerList")
);

const ManageVendorsList = React.lazy(() =>
  import("../components/vendors/vendors-list/ManageVendorsList")
);

const ManageSuppliesList = React.lazy(() =>
  import("../components/supplies/supplies-list/ManageSuppliesList")
);

const ManageDefaultBillingRates = React.lazy(() =>
  import("../components/default-billing-rates/ManageDefaultBillingRates")
);

const ApartmentSizeMappings = React.lazy(() =>
  import("../components/apartment-size-mapping/ApartmentSizeMappingList")
);

const ManageSupplyPurchaseHeaders = React.lazy(() =>
  import("../components/supplies/supply-purchases/ManageSupplyPurchaseHeaders")
);

const ManageSupplyPurchases = React.lazy(() =>
  import("../components/supplies/supply-purchases/ManageSupplyPurchases")
);

const HoldingTank = React.lazy(() =>
  import("../components/holding-tank/HoldingTank")
);

const BidCreator = React.lazy(() =>
    import("../components/bid-creator/BidCreator")
);

const routes = [
  {
    path: "/holding-tank",
    exact: true,
    name: "Holding Tank",
    component: HoldingTank,
  },
  {
    path: "/bid-creator",
    exact: true,
    name: "Bid Creator",
    component: BidCreator,
  },
  {
    path: "/daily-sheet-review",
    exact: true,
    name: "Daily Sheet Review",
    component: ManageDailySheetReview,
  },
  {
    path: "/daily-sheet",
    exact: true,
    name: "Daily Sheet",
    component: ManageDailySheetsList,
  },
  {
    path: "/apartment-size-mappings",
    exact: true,
    name: "Apartment Size Mappings",
    component: ApartmentSizeMappings,
  },
  {
    path: "/complexes/complex-detail/:id",
    exact: true,
    name: "Complex Detail",
    component: ComplexDetail,
  },
  {
    path: "/complexes/create-complex/",
    exact: true,
    name: "Create New Complex",
    component: ComplexDetail,
  },
  {
    path: "/complexes/",
    exact: true,
    name: "Complexes",
    component: ManageComplexesList,
  },
  {
    path: "/invoicing/invoice-status",
    exact: true,
    name: "Invoice Status",
    component: ManageInvoiceStatusList,
  },
  {
    path: "/invoicing/manage-invoice/:invoiceNumber",
    exact: true,
    name: "Manage Invoice",
    component: ManageReadyForInvoiceList,
  },
  {
    path: "/invoicing/contracts",
    exact: true,
    name: "Contracts",
  },
  {
    path: "/invoicing/ready-for-invoice",
    exact: true,
    name: "Ready For Invoice",
    component: ManageReadyForInvoiceList,
  },
  {
    path: "/employee-management/",
    exact: true,
    name: "Employee Management",
    component: ManageEmployeeList,
  },
  {
    path: "/:complexId/customer-management/",
    exact: true,
    name: "Customer Management",
    component: ManageCustomerList,
  },
  {
    path: "/customer-management/",
    exact: true,
    name: "Customer Management",
    component: ManageCustomerList,
  },
  {
    path: "/vendors/",
    exact: true,
    name: "Vendors",
    component: ManageVendorsList,
  },
  {
    path: "/supplies/",
    exact: true,
    name: "Supply Prices",
    component: ManageSuppliesList,
  },
  {
    path: "/supply-purchases",
    exact: true,
    name: "Supply Purchases",
    component: ManageSupplyPurchaseHeaders,
  },
  {
    path: "/supply-purchases/:id",
    exact: true,
    name: "Manage Purchases",
    component: ManageSupplyPurchases,
  },
  {
    path: "/my-profile/view-issues",
    exact: true,
    name: "View Issues",
    component: ManageEmployeeIssues,
  },
  {
    path: "/employee-management/employee-detail/:id/manage-issues",
    exact: true,
    name: "Manage Issues",
    component: ManageEmployeeIssues,
  },
  {
    path: "/my-profile",
    exact: true,
    name: "My Profile",
    component: EmployeeDetail,
  },
  {
    path: "/employee-management/employee-detail/:id",
    exact: true,
    name: "Employee Detail",
    component: EmployeeDetail,
  },
  {
    path: "/employee-management/create-employee",
    exact: true,
    name: "Create New Employee",
    component: EmployeeDetail,
  },
  {
    path: "/my-account",
    exact: true,
    name: "My Account",
    component: CustomerDetail,
  },
  {
    path: "/customer-management/customer-detail/:id",
    exact: true,
    name: "Customer Detail",
    component: CustomerDetail,
  },
  {
    path: "/customer-management/create-customer",
    exact: true,
    name: "Create New Customer",
    component: CustomerDetail,
  },
  {
    path: "/:complexId/customer-management/customer-detail/:id",
    exact: true,
    name: "Customer Detail",
    component: CustomerDetail,
  },
  {
    path: "/:complexId/customer-management/create-customer",
    exact: true,
    name: "Create New Customer",
    component: CustomerDetail,
  },
  {
    path: "/billing-rates",
    exact: true,
    name: "Billing Rates",
    component: ManageDefaultBillingRates,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
  },
  {
    path: "/paint-store-detail/:id",
    exact: true,
    name: "Editing Paint Store",
  },
  {
    path: "/management-company-detail/:id",
    exact: true,
    name: "Editing Management Company",
  },
  {
    path: "/manage-customer-jobs",
    exact: true,
    name: "Ordered Jobs",
  },
  {
    path: "/manage-team-schedules",
    exact: true,
    name: "Scheduled Teams",
  },
  {
    path: "/create-schedule",
    exact: true,
    name: "New Schedule",
  },
  {
    path: "/view-schedule",
    exact: true,
    name: "View Schedule",
    component: ViewSchedule,
  },
  {
    path: "/reporting/lead-bonus",
    exact: true,
    name: "Lead Bonus Report",
    component: LeadBonusReport,
  },
  {
    path: "/reporting/big-job",
    exact: true,
    name: "Big Job",
  },
  {
    path: "/reporting/invoiced-charges",
    exact: true,
    name: "Charges By Complex Report",
  },
  {
    path: "/reporting/invoiced-lead-charges",
    exact: true,
    name: "Charges By Lead Report",
  },
  {
    path: "/reporting/management-company",
    exact: true,
    name: "Management Company Report",
  },
  {
    path: "/reporting/lead-complex-task-count",
    exact: true,
    name: "Lead Task Count",
  },
  {
    path: "/reporting/cleaner-pay",
    exact: true,
    name: "Cleaner Pay",
  },
];

export default routes;
