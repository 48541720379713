const _leadnav = [
  {
    _tag: "CSidebarNavItem",
    name: "Schedule",
    to: "/view-schedule",
    icon: "cil-clipboard",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Daily Sheets",
    to: "/daily-sheet",
    icon: "cil-list",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Reporting",
    route: "/reporting",
    icon: "cil-bar-chart",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/lead-bonus",
        name: "Lead Bonus Report",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/cleaner-pay",
        name: "Cleaner Pay",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "My Profile",
    to: "/my-profile",
    icon: "cil-group",
  },
];

export default _leadnav;
