const _invoicingmanagernav = [
  {
    _tag: "CSidebarNavDropdown",
    name: "Schedule",
    icon: "cil-clipboard",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/manage-customer-jobs",
        name: "Ordered Jobs",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/manage-team-schedules",
        name: "Scheduled Teams",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Daily Sheets",
    icon: "cil-list",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/daily-sheet",
        name: "Daily Sheet",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/daily-sheet-review",
        name: "Daily Sheet Review",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Complexes",
    icon: "cil-building",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/complexes",
        name: "Complex Management",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/apartment-size-mappings",
        name: "Apartment Size Mappings",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Invoicing",
    route: "/invoicing",
    icon: "cil-description",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/invoicing/ready-for-invoice",
        name: "Ready For Invoice",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/invoicing/invoice-status",
        name: "Invoice Status",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/invoicing/contracts",
        name: "Contracts",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Billing Rates",
    to: "/billing-rates",
    icon: "cil-cash",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Reporting",
    route: "/reporting",
    icon: "cil-bar-chart",
    _children: [
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/big-job",
        name: "Big Job",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/invoiced-charges",
        name: "Charges By Complex",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/invoiced-lead-charges",
        name: "Charges By Lead",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/lead-bonus",
        name: "Lead Bonus Report",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/lead-complex-task-count",
        name: "Lead Task Count",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/management-company",
        name: "Management Company",
      },
      {
        _tag: "CSidebarNavItem",
        to: "/reporting/cleaner-pay",
        name: "Cleaner Pay",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Employee Management",
    to: "/employee-management",
    icon: "cil-group",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Vendors",
    to: "/vendors",
    icon: "cil-paint-bucket",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Supplies",
    icon: "cil-paint",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Prices",
        to: "/supplies",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Purchases",
        to: "/supply-purchases",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Settings",
    to: "/settings",
    icon: "cil-settings",
  },
];

export default _invoicingmanagernav;
