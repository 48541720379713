const _customernav = [
  {
    _tag: "CSidebarNavItem",
    name: "Schedule",
    to: "/manage-customer-jobs",
    icon: "cil-clipboard",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Invoicing",
    to: "/invoicing/invoice-status",
    icon: "cil-description",
  },
  {
    _tag: "CSidebarNavItem",
    name: "My Account",
    to: "/my-account",
    icon: "cil-group",
  },
];

export default _customernav;
