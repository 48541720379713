import { divisionTypes } from "../constants/division-types";

export const printAsDollars = (number) => {
  if (!number) {
    number = 0;
  }
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const getDivisionPrimaryButtonClass = (division) => {
  if (division === "Hallway Clean") {
    division = "Clean";
  }
  return `btn akp-btn-primary-${
    division?.label
      ? division.label.toLowerCase()
      : division
      ? division.toLowerCase()
      : divisionTypes.ALL.toLowerCase()
  } `;
};

export const getDivisionDisabledInputHighlightClass = (division, value) => {
  if (division === "Hallway Clean") {
    division = "Clean";
  }

  if (value !== "Completed Previously" && value !== "Yes" && value !== true) {
    return "";
  }

  return `akp-disabled-input-highlight-${
    division?.label
      ? division.label.toLocaleLowerCase()
      : division
      ? division.toLocaleLowerCase()
      : divisionTypes.ALL.toLowerCase()
  }`;
};

export const getDivisionSecondaryButtonClass = (division) => {
  if (division === "Hallway Clean") {
    division = "Clean";
  }
  return `btn akp-btn-secondary-${
    division?.label
      ? division.label.toLowerCase()
      : division
      ? division.toLowerCase()
      : divisionTypes.ALL.toLowerCase()
  } `;
};

export const getDivisionTertiaryButtonClass = (division) => {
  if (division === "Hallway Clean") {
    division = "Clean";
  }
  return `btn akp-btn-tertiary-${
    division?.label
      ? division.label.toLowerCase()
      : division
      ? division.toLowerCase()
      : divisionTypes.ALL.toLowerCase()
  } `;
};

export const defaultDivision = divisionTypes.ALL;

export const getDefaultColorVariableSuffix = () => {
  return "_All";
};
