import React, { useContext, useState } from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import AllKindLogo from "../../assets/AllKindLogo.png";

// sidebar nav config
import _frontdesknav from "./navigation/_frontdesknav";
import _adminnav from "./navigation/_adminnav";
import _schedulingmanagernav from "./navigation/_schedulingmanagernav";
import _leadnav from "./navigation/_leadnav";
import _customernav from "./navigation/_customernav";
import _employeenav from "./navigation/_employeenav";
import _qcManagernav from "./navigation/_qcmanagernav";

import authService from "../../components/api-authorization/AuthorizeService";
import _invoicingmanagernav from "./navigation/_invoicingmanagernav";
import UserContext from "../../data/UserContext";
import { divisionTypes } from "../../constants/division-types";

const TheSidebar = ({ sidebarShow, toggleSidebar }) => {
  const user = useContext(UserContext);

  const [isLoggedIn, setLoginStatus] = useState(false);

  const [isAdmin, setAdminStatus] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [isFrontDesk, setIsFrontDesk] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isSchedulingManager, setIsSchedulingManager] = useState(false);
  const [isInvoicingManager, setIsInvoicingManager] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isQCManager, setIsQCManager] = useState(false);

  authService.isAuthenticated().then(
    function (value) {
      if (value) {
        setLoginStatus(value);
        authService.isAdmin().then(function (admin) {
          setAdminStatus(admin);
        });
        authService.isEmployee().then(function (employee) {
          setIsEmployee(employee);
        });
        authService.isLead().then(function (lead) {
          setIsLead(lead);
        });
        authService.isCustomer().then(function (customer) {
          setIsCustomer(customer);
        });
        authService.isFrontDesk().then(function (frontDesk) {
          setIsFrontDesk(frontDesk);
        });
        authService.isSchedulingManager().then(function (schedulingManager) {
          setIsSchedulingManager(schedulingManager);
        });
        authService.isInvoicingManager().then(function (invoicingManager) {
          setIsInvoicingManager(invoicingManager);
        });
        authService.isQCManager().then(function (qcManager) {
          setIsQCManager(qcManager);
        });
      }
    },
    function (error) {
      console.log("Error");
    }
  );

  const renderNav = () => {
    if (isLoggedIn) {
      if (isAdmin) {
        return (
          <CCreateElement
            items={_adminnav}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        );
      }

      if (isFrontDesk) {
        return (
          <CCreateElement
            items={_frontdesknav}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        );
      }

      if (isCustomer) {
        return (
          <CCreateElement
            items={_customernav}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        );
      }

      if (isEmployee) {
        return (
          <CCreateElement
            items={_employeenav}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        );
      }

      if (isQCManager) {
        return (
          <CCreateElement
            items={_qcManagernav}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        );
      }

      let items = [];

      if (isSchedulingManager) {
        items = items.concat(_schedulingmanagernav);
      }

      if (isLead && !isSchedulingManager) {
        if (user && user.division && user.division !== divisionTypes.CLEAN) {
          const navIndex = _leadnav.findIndex((n) => n.name === "Reporting");
          if (navIndex !== -1 && _leadnav[navIndex]._children) {
            const childIndex = _leadnav[navIndex]._children.findIndex(
              (c) => c.name === "Cleaner Pay"
            );

            if (childIndex !== -1) {
              _leadnav[navIndex]._children.splice(childIndex, 1);
            }
          }
        }

        if (user && user.division && user.division !== divisionTypes.PAINT) {
          const navIndex = _leadnav.findIndex((n) => n.name === "Reporting");
          if (navIndex !== -1 && _leadnav[navIndex]._children) {
            const childIndex = _leadnav[navIndex]._children.findIndex(
              (c) => c.name === "Lead Bonus Report"
            );

            if (childIndex !== -1) {
              _leadnav[navIndex]._children.splice(childIndex, 1);
            }
          }
        }

        if (user && user.division && user.division === divisionTypes.SHAMPOO) {
          const navIndex = _leadnav.findIndex((n) => n.name === "Reporting");
          if (navIndex !== -1) {
            _leadnav.splice(navIndex, 1);
          }
        }

        items = items.concat(_leadnav);
      }

      if (isInvoicingManager) {
        items = items.concat(_invoicingmanagernav);
      }

      if (isQCManager) {
        items = items.concat(_qcManagernav);
      }
      return (
        <CCreateElement
          items={items}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      );
    }
  };

  return (
    <CSidebar show={sidebarShow} hideOnMobileClick onShowChange={toggleSidebar}>
      <button
        className="mobileMenuButton invis-btn float-right"
        onClick={() => toggleSidebar()}
      >
        <i className="fa fa-chevron-left fa-2x"></i>
      </button>
      <div className="logo-container">
        <img
          className="sidebar-logo"
          src={AllKindLogo}
          alt="All Kind Painting and Cleaning"
        />
      </div>
      <CSidebarNav>{renderNav()}</CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
