import React, { useState, useEffect, useCallback } from "react";
import { getEmployeeByUserId } from "../api/employeeApi";
import authService from "../components/api-authorization/AuthorizeService";
import UserContext from "./UserContext";

const UserContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();
  const fetchUser = useCallback(async () => {
    const user = await authService.getUser();

    if (user) {
      const isCustomer = await authService.isCustomer();
      if (!isCustomer) {
        const employeeDetail = await getEmployeeByUserId(user?.id);
        user.division = employeeDetail.division;
        user.employeeDetailId = employeeDetail.id;
        user.employeeName = `${employeeDetail.firstName} ${employeeDetail.lastName}`;
      }
      user.roles =
        user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ||
        [];
    }

    setCurrentUser(user);
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      fetchUser();
    }

    return () => {
      isSubscribed = false;
    };
  }, [fetchUser]);

  return (
    <UserContext.Provider value={currentUser}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
