import React, { Component } from "react";
import Loading from "./components/common/Loading";
import { Route, BrowserRouter } from "react-router-dom";
import "./coreui/scss/style.scss";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { Login } from "./components/api-authorization/Login";
import { Logout } from "./components/api-authorization/Logout";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import TheLayout from "./coreui/containers/TheLayout";

if (process.env.REACT_APP_AI_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
      /* ...Other Configuration Options... */
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

const loading = <Loading />;

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="authentication//login-callback"
            name="Log callback Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/logout"
            name="Login Call back Page"
            render={(props) => <Logout {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />
        </React.Suspense>
        <ToastContainer autoClose={3000} position="top-center" />
      </BrowserRouter>
    );
  }
}

export default App;
